<template>
    <div>
        <h2>{{ $t("horse.pension") }}</h2>

        <div class="box">
            <CustomTable
                ref="pension"
                id_table="horse_pension"
                :items="filtredHorsePension"
                :busy.sync="table_busy"
                primaryKey="horsepension_id"
                :hide_if_empty="true"
                :rawColumns="rawColumnNames"
				:display_action_button="!horseFicheReadOnly"
				:checkboxes="!horseFicheReadOnly"
            />

            <b-modal ref="modal" size="xxl" hide-footer>
                <template v-slot:modal-title>
                    <template v-if="ajouter">
                        {{ $t("horse.ajouter_pension") }}
                    </template>

                    <template v-if="modifier">
                        {{ $t("horse.modifier_pension") }}
                    </template>
                </template>
                
                <PensionAjout 
                    :add-or-edit-ready.sync="addOrEditReady"
                    :disable-button.sync="disableButton"
                    ref="PensionAjout"
                    :horsepension_id="horsepension_id"
                    :horse="horse"
                    :horse_pension="horse_pension"
                />

                <b-button v-if="ajouter && addOrEditReady && !disableButton" class="mt-3 btn-block rounded-pill" block @click.prevent="checkForm" variant="primary"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.ajouter") }}</b-button>
                <b-button v-if="modifier && addOrEditReady && !disableButton" class="mt-3 btn-block rounded-pill" block @click.prevent="checkForm" variant="primary"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.modifier") }}</b-button>
            </b-modal>

            <b-modal ref="modal_end" hide-footer>
                <template v-slot:modal-title>{{ $t("horse.modifier_pension") }}</template>
                
                <div class="col-12" v-if="pension_edit">
                    <div class="form-group">
                        <label for="date_cloture" class="col-form-label">{{ $t("horse.date_cloture") }}</label>
                        <e-datepicker id="date_cloture" :min-date="pension_edit.horsepension_end_datetime" v-model="pension_edit.date_end"></e-datepicker>
                    </div>
                </div>

                <b-button class="mt-3 btn-block rounded-pill" block @click.prevent="checkFormEnd" variant="primary"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.modifier") }}</b-button>
            </b-modal>
        </div>

        <PensionHistorique ref="PensionHistorique" :horse_id="horse.horse_id" />
    </div>
</template>

<script type="text/javascript">
    import Vue from 'vue'
    import Common from '@/assets/js/common'
    import TableAction from "@/mixins/TableAction.js"
    import Navigation from "@/mixins/Navigation.js";
    import HorseMixin from "@/mixins/Horse.js"
    import PensionMixin from "@/mixins/Pension.js"
    import Tools from "@/mixins/Tools.js"

    export default {
        name: "HorsePension",
        mixins: [TableAction, Navigation, HorseMixin, PensionMixin, Tools],
        props: ['horse'],
        data () {
            return {
                addOrEditReady: false,
                disableButton: false,
                processing: false,
                ajouter: false,
                modifier: false,

                horsepension_id: null,
                horse_pension: null,
                pension_edit: null,

                rawColumnNames: ['pension_articles', 'pension_ht', 'pension_vat', 'pension_ttc'],
                table_busy: true,
                events_tab: {
                    'TableAction::goToAddHorsePension': () => {
                        this.addOrEdit()
                    },
                    'TableAction::goToEditHorsePension': (params) => {
                        this.addOrEdit(params.horsepension_id).then(() => {
                            this.unselectAll()
                        })
                    },
                    'TableAction::goToEditEndHorsePension': (params) => {
                        this.editEnd(params)
                    },
                    'TableAction::goToDeleteHorsePension': (params) => {
                        this.deleteHorsePension(params.horsepension_id)
                        .then(() => {
                            this.unselectAll()
                        })

                    },
                    'DeleteHorsePensionHistoriqueSuccess': () => {
                        this.loadPensionComponent()
                    },
                }
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                await this.loadPensionComponent()
            },
            async loadPensionComponent() {
                this.table_busy = true
                const pensions = await this.loadCurrentPension(this.horse.horse_id)
                if(pensions) {
                    let temp = []
                    const now = new Date()
                    await Common.asyncForEach(
                        pensions,
                        async (pension) => {
                            if(pension.pension) {
                                const pension_startdate = this.formatDate(pension.horsepension_start_format)
                                const pension_date = this.formatDate(pension.horsepension_start)
                                const date_prefix = pension_date < now ? this.getTrad("horse.depuis_le") : this.getTrad("horse.a_partir_du")
                                pension.pension_label = pension.pension.pension_label
                                pension.pension_date = `${date_prefix} ${pension_startdate}`
                                pension.pension_articles = ''
                                pension.pension_ht = ''
                                pension.pension_vat = ''
                                pension.pension_ttc = ''

                                if(pension.horsepension_article) {
                                    const articles = pension.horsepension_article
                                    await Common.asyncForEach(
                                        articles,
                                        async (article) => {
                                            pension.pension_articles = pension.pension_articles + article.horsepensionarticle_article.articles_label + "<br>"
                                            pension.pension_ht = pension.pension_ht + await this.priceFormat(article.horsepensionarticle_ht, article.tiers_currency) + "<br>"
                                            pension.pension_vat = pension.pension_vat + article.horsepensionarticle_article.vat.vat_label + "<br>"
                                            pension.pension_ttc = pension.pension_ttc + await this.priceFormat(article.horsepensionarticle_ttc, article.tiers_currency) + "<br>"
                                        }
                                    )
                                }

                                temp.push(pension)
                            }
                        }
                    )

                    this.horse_pension = temp
                } else {
                    this.horse_pension = null
                }

                this.table_busy = false
            },
            async addOrEdit(horsepension_id) {
                this.processing = false
                this.addOrEditReady = false
                if(horsepension_id) {
                    this.ajouter = false
                    this.modifier = true
                    this.horsepension_id = horsepension_id
                } else {
                    this.ajouter = true
                    this.modifier = false
                    this.horsepension_id = null
                }

                this.$refs.modal.show()
            },
            async checkForm() {
                if(!this.processing) {
                    this.processing = true
                    let result = await this.$refs.PensionAjout.checkForm()
                    if(result) {
                        this.$refs.modal.hide()
                        this.init_component()
                        this.$refs.PensionHistorique.loadPensionHistoriqueComponent()
                    } else {
                        this.processing = false
                    }
                }
            },
            async deleteHorsePension(horsepension_id) {
                this.deletePension(this.horse.horse_id, horsepension_id)
                .then(result => {
                    this.init_component()
                    this.$refs.PensionHistorique.loadPensionHistoriqueComponent()
                    this.successToast('toast.info_modif_succes')
                })
            },
            editEnd(pension) {
                this.processing = false
                this.pension_edit = pension
                this.pension_edit.date_end = new Date(pension.horsepension_end)
                this.$refs.modal_end.show()
            },
            async checkFormEnd() {
                if(!this.processing) {
                    this.processing = true
                    if(this.pension_edit.date_end === null){
                        this.processing = false
                        this.failureToast('toast.date_mandatory')
                        return
                    }
                    if(this.horse_pension[0]){
                        if(Date.parse(this.pension_edit.date_end) > Date.parse(this.horse_pension[0].horsepension_start)){
                            this.processing = false
                            this.failureToast('toast.info_save_failed')
                            return
                        }
                    }
                    const result = await this.editPension(
                            this.pension_edit.horsepension_id,
                            this.pension_edit.horsepension_horse,
                            new Date(this.pension_edit.horsepension_start),
                            this.pension_edit.date_end,
                            this.pension_edit.pension.pension_id,
                            this.pension_edit.horsepension_article
                        );
                    if(result) {
                        this.processing = false
                        this.pension_edit = null
                        this.$refs.modal_end.hide()
                        this.init_component()
                        this.$refs.PensionHistorique.loadPensionHistoriqueComponent()
                    } else {
                        this.processing = false
                    }
                }
            },
            unselectAll() {
        		this.$refs.pension.unselectAll()
        	}
        },
        computed: {
			horseFicheReadOnly() {
                return this.$store.state.horseFicheReadonly
            },
            filtredHorsePension: function() {
                return this.horse_pension
            },
            horseProps: function() {
                return this.horse
            }
        },
        watch: {
            "horseProps" (val, prev) {
                if(val.horse_id !== prev.horse_id) {
                    this.init_component()
                }
            }
        },
        components: {
            LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
            PensionAjout : () => import('@/components/Horse/PensionAjout'),
            PensionHistorique : () => import('@/components/Horse/PensionHistorique')
        }
    }
</script>
